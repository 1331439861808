import React, { useEffect } from "react";
import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { useLocation } from "react-use";

import { ContextProvider } from "components/context";

import Header from "components/header";

const queryClient = new QueryClient();

const options = {
  duration: 1.2,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  direction: "vertical",
  gestureDirection: "vertical",
  smooth: true,
  mouseMultiplier: 1,
  smoothTouch: false,
  touchMultiplier: 2,
  infinite: false,
};

function Layout({ children }) {
  const location = useLocation();
  const lenis = useLenis();

  useEffect(() => {
    if (!location.hash.includes("#")) {
      lenis?.scrollTo(0, {
        immediate: true,
      });
    }
  }, [location.pathname]);
  return (
    <QueryClientProvider client={queryClient}>
      <ContextProvider>
        <ReactLenis root options={options}>
          <Header />
          <main>{children}</main>
        </ReactLenis>
      </ContextProvider>
    </QueryClientProvider>
  );
}

export default Layout;
