import React from "react";
import {
  IoMail,
  IoPaperPlane,
  IoCallOutline,
  IoLogoWhatsapp,
} from "react-icons/io5";

export const contacts = [
  {
    name: "+7 999 993 17 10",
    icon: <IoCallOutline />,
    link: "tel:+79999931710",
  },
  {
    name: "info@mrc-club.ru",
    icon: <IoMail />,
    link: "mailto:info@mrc-club.ru",
  },
  {
    name: "WhatsApp",
    icon: <IoLogoWhatsapp />,
    link: "https://wa.me/79999931710",
  },
  {
    name: "Telegram",
    icon: <IoPaperPlane />,
    link: "https://t.me/+79999931710",
  },
];

export const links = [
  {
    name: "Я спикер",
    href: "/",
  },
  {
    name: "Я журналист",
    href: "/journalist",
  },
  {
    name: "О нас",
    href: "/about",
  },
  {
    name: "Публикации в СМИ",
    href: "/media",
  },
  {
    name: "Новости компании",
    href: "/news",
  },
  {
    name: "Пресс-мероприятия",
    href: "/#events",
  },
  {
    name: "Тарифы чат-ботов",
    href: "/price",
  },
  {
    name: "Услуги",
    href: "/#services",
  },
];
