import React, { createContext, useContext, useState, useEffect } from "react";
import { useLenis } from "@studio-freight/react-lenis";

const StateContext = createContext({
  service: {
    visible: false,
    id: "",
    name: "",
    content: null,
  },
  event: {
    visible: false,
  },
  chatbot: {
    title: "",
    visible: false,
  },
  setService: () => {},
  setEvent: () => {},
  setChatbot: () => {},
});

export const ContextProvider = ({ children }) => {
  const [service, setService] = useState({
    visible: false,
    id: "",
    name: "",
    content: null,
  });
  const [event, setEvent] = useState({
    visible: false,
  });
  const [chatbot, setChatbot] = useState({
    title: "",
    visible: false,
  });

  const lenis = useLenis();

  useEffect(() => {
    if (lenis) {
      if (service.visible || event.visible) {
        lenis.stop();
      } else {
        lenis.start();
      }
    }
  }, [service.visible, event.visible]);

  return (
    <StateContext.Provider
      value={{
        service,
        event,
        chatbot,
        setService,
        setEvent,
        setChatbot,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
